@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
body {
    background-color: #F6F6F6;
}
Header {
    background-image: url(../src/images/header-background.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 800px;
    padding-top: 50px;
}
h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}
h1, h2 {
    font-family: "Krona One", sans-serif;
    font-weight: 400;
    font-style: normal;
}
h1 {
    font-size: 45px;
}
h2 {
    font-size: 35px;
}
h3{
    font-size: 20px;
}
p, ul li, button{
    font-size: 16px;
    font-family: "Raleway", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
}
.btn-nav button, .btn-orange {
    /*background-color: #F18F01;*/
    color: #F1F1F1;
    border: none;
    font-weight: bold;
    line-height: 22px;
    width: 150px;
    height: 55px;
    border-radius: 5px;
    font-weight: bold;
    line-height: 20px;
    cursor: pointer;
    height: 40px;
    width: 400px;
}
.btn-rose button {
    background-color: #FF6B8E;
    border: none;
    color: #fff;
    width: 300px;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
}
.btn-paires {
    text-align: center;
}
.text-orange {
    color: #F18F01;
}

.logo {
    width: 10%;
}
.logo svg {
    width: 90%;
}
.btn-signin {
    border: none;
    color: #fff;
    width: fit-content;
    padding: 20px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    font-family: "Raleway", sans-serif;
    background-color: #000;
}
.justify-content {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 30%;
}
nav {
    display: flex;
    justify-content: space-between;
    padding: 40px 60px;
    border-radius: 24px;
    background: var(--White-100, #FFF);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    align-items: center;
    height: 20px;
    width: 90%;
    margin: auto;
}
.header-text {
    padding-left: 60px;
}
.header-text h1 {
    text-transform: uppercase;
    line-height: 50px;
    margin-bottom: 20px;
    margin-top: 127px;
}
.header-text p {
    width: 25%;
}
.scroll p {
    font-weight: bold;
}
.scroll {
    width: 50%;
    margin: 216px auto 0;
    text-align: center;
    cursor: pointer;
}
.concept {
    background-repeat: no-repeat;
    background-size: contain;
    padding: 80px;
}
.bg-concept-artist {
    background-image: url('../src/images/bg-concept.png');
    background-repeat: no-repeat;
    background-size: 370px;
    background-position-x: right;
}
.bgArtist {
    position: absolute;
    left: 0;
    width: 700px;
    bottom: 5%;
}
.concept_title p {
    text-transform: uppercase;
}
.left_concept {
    width: 50%;
}

/* .left_concept span, .text-orange {
    color: #F18F01;
} */
.text-orange span {
    font-size: 80px;
}
.right_concept {
    width: 50%;
    margin-top: 30px;
    margin-left: 100px;
}
.d-flex-concept {
    display: flex;
}
.d-flex {
    display: flex;
}
.concept_stat {
    display: flex;
}
.left_stat {
    margin-right: 30px;
}
.valeurs {
    background-image: url('../src/images/bg-valeurs.png');
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: 250px;
    padding: 80px;
}
.titles {
    
    margin-bottom: 50px;
}
.titles p, .titles-products p {
    text-transform: uppercase;
}

.cards-valeurs {
    display: flex;
    padding: 50px 0;
    justify-content: space-between;
    align-items: center;
}
.card-item {
    width: 30%;
}
.card-item h3 {
    margin: 20px 0 10px;
}
.products {
    padding: 80px;
}
/* Carousel */
* {
    margin: 0;
    padding: 0;
  }
  
  .carousel {
    position: relative;
    width: 90%;
}

.carousel-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 99%;
}

.carousel-slide {
    display: flex;
    align-items: flex-start;
}
.carousel-slide p {
    text-align: center;
    margin: 0;
}
.carousel .card {
    min-width: 800px;
    box-sizing: border-box;
    padding: 20px;
    text-align: center;
    margin: 5px;
    height: 400px;
    border-radius: 20px;
    
}
.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
}
.carousel-section {
    width: 100%;
    margin: 50px auto;
}

.p-title {
    margin-bottom: 20px;

}
.img1 {
    background-image: url(../src/images/2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.img2 {
    background-image: url(../src/images/3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.img3 {
    background-image: url(../src/images/4.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.img4 {
    background-image: url(../src/images/6.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.img5 {
    background-image: url(../src/images/7.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.card-container p {
    font-weight: bold;
}


.prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
}

.prev {
    left: 0;
}

.next {
    right: 0;
}

.dots {
    margin-top: 50px;
    padding: 10px;
}

.dot {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}

.active, .dot:hover {
    background-color: #FF6B8E;
}
.concept-title {
    margin-bottom: 30px;
}
.blog {
    padding: 80px;
    color: #fff;
    background-color: #171717;
    border-radius: 30px;
    height: auto;
}
.p-t-0 {
    padding-top: 0 !important;
}
.title-m {
    margin-bottom: 30px;
}
.visit_blog {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.lien-blog, .lien-article {
    display: flex;
    align-items: center;
}
.lien-blog p, .lien-article p {
    margin-right: 10px;
    color: #F18F01;
    cursor: pointer;
    font-weight: bold;
}
.img-article {
    background-image: url(../src/images/8.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
    border-radius: 10px;
}
.left_blog{
    width: 50%;
}
.visit_blog h2 {
    margin-bottom: 50px;
}
.left_blog .tag-blog {
    margin: 30px 0;
}
.left_blog .lien-article {
    margin-top: 30px;
}
.right_blog .img-card {
    width: 42%;
}
.img-card img {
    width: 80%;
    max-width: 100%;
}
/* .img-a1 {
    background-image: url(../src/images/9.png);
}
.img-a2 {
    background-image: url(../src/images/10.png);
}
.img-a3 {
    background-image: url(../src/images/11.png);
} */
.right_blog {
    margin-left: 40px;
    width: 50%;
}
.card-article {
    display: flex;
    width: 100%;
}
.tag-blog {
    margin-bottom: 30px;
}
.article-text h3 {
    margin-bottom: 55px;
    width: 60%;
}
.border-bottom {
    border-bottom: 1px solid #F6F6F6;
    padding: 40px 0;
}
.p-40 {
    padding: 40px 0;
}
.p-left-80 {
    padding-left: 80px;
}
footer {
    position: relative;
    height: auto;
    text-align: center;
    padding-top: 150px;
    background-position-x: center;
    margin-top: 60px;
}
.bg-arrow-top {
    position: absolute;
    right: 0;
    top: 0;
}
.title-uppercase {
    text-transform: uppercase;
}
.couronne {
    transform: translateX(-36px);
}
.left-title, .right-title {
    display: none;
}
.m-b-title {
    margin-bottom: 10px;
}

.title-footer h2{
    margin: 20px 0;
}
.input-newsleter {
    display: block;
    width: 30%;
    margin: 30px auto 150px;
}
.input-newsleter input, .input-newsleter textarea {
    background: #171717;
    border-radius: 15px;
    border: 0.1px solid #000;
    width: 100%;
    margin: 5px 0;    
    color: #f1f1f1;
    font-family: "Raleway", sans-serif;

}

.input-newsleter textarea {
    height: 200px;
    padding: 20px 0 0px 20px;}
.input-newsleter input {
    height: 50px;
    padding-left: 20px;

}
.submit {
    border-radius: 10px;
    border: none;
    cursor: pointer;
    height: 40px;
    width: 200px;
    color: #fff;
    background-color: #FF6B8E;

}

 
::placeholder {
    color: #f1f1f1;
    font-family: "Raleway", sans-serif;
    padding-left: 10px;
}
.input-newsleter-sign button {
    background-color: #FF6B8E;
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    transform: translate3d(227px, -65px, 10px);
    cursor: pointer;
    height: 50px;
    font-weight: bold;
    color: #fff;
} 
.input-newsleter-sign input {
    height: 67px !important;
}
.logo-footer {
    text-align: start;
}
.logo-footer svg {
    width: 130px;
    max-width: 100%;
}
.footer-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 80px;
}
.copyright {
    width: 40%;
    text-align: center;
}
.copyright p {
    font-weight: bold;
}
.rs-img img {
    margin-right: 10px;
}
.btn-nav {
    margin-top: 50px;
}
.nav-mobile {
    display: none;
}
.h1-mobile {
    display: none;
}
.scroll-mobile {
    display: none;

}
.carousel-mobile {
    display: none;
}
.d-flex-blog {
    display: flex;
}
.copyright-mobile {
    display: none;
}
.f-bold {
    font-weight: bold;
}
.couronne-mobile {
    display: none;
}
.paires {
    padding: 80px;
}
.titles-custom {
    padding: 80px;
}
.gallery-sneakers img {
    width: 50%;
    max-width: 100%;
}
.gallery-sneakers {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}
.gallery-artist img {
    width: 45%;
}
.gallery-artist {
    color: #fff;
    background-color: #171717;
    border-radius: 30px;
    height: 800px;
}
.gallery-artist {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #fff;
    background-color: #171717;
    border-radius: 30px;
    height: 800px;
    padding: 20px;
    overflow: hidden;
  }

  .artist h3 {
    margin: 10px 0 5px;
    font-size: 18px;
  }
  .artist p {
    font-size: 14px;
    color: #bbb;
  }
  .artist-s img {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
  }
 .title-upper {
    text-transform: uppercase;
 }
  .text-blue {
    color: #8EBBFF;
  }
  .size-80 span{
    font-size: 80px;
  }
  .text-rose {
    color:  #FF6B8E;
  }
  .title-m-b {
    margin-bottom: 50px;
  }
  .text-violet {
    color: #CEBEFD;
  }
  .artist-1 {
    position: absolute;
    left: 19%;
    top: 10%;
  }
  .artist-2 {
    position: absolute;
    left: 10%;
    top: 25%;
  }
  .artist-3 {
    position: absolute;
    left: 43%;
    top: 7%;
  }
  .artist-4 {
    position: absolute;
    left: 80%;
    top: 6%;
  }
  .artist-5 {
    position: absolute;
    left: 27%;
    top: 28%;
  }
  .artist-6 {
    position: absolute;
    left: 66%;
    top: 16%;
  }
  .artist-7 {
    position: absolute;
    left: 87%;
    top: 25%;
  }
  .artist-8 {
    position: absolute;
    left: 20%;
    top: 48%;
  }
  .artist-9 {
    position: absolute;
    left: 75%;
    top: 38%;
  }
  .artist-10 {
    position: absolute;
    left: 10%;
    top: 78%;
  }
  .artist-11 {
    position: absolute;
    left: 34%;
    top: 65%;

  }
  .artist-12 {
    position: absolute;
    left: 57%;
    top: 58%;
  }
  .artist-13 {
    position: absolute;
    left: 86%;
    top: 74%;
  }
  .artist-14 {
    position: absolute;
    left: 45%;
    top: 40%;
  }
  .artist-b {
    width: 300px;
    height: 300px;
  }
  .btn-position {
    position: absolute;
    bottom: 5%;
  }
  .liens {
    width: 50%;
  }
  .liens  ul{
    display: flex;
  }
  .liens ul {
    list-style-type: none;
    justify-content: space-around;

  }
  .liens ul li {
    margin-right: 20px;
    font-weight: bold;
    cursor: pointer;
  }
  .text-width {
    margin: auto;
    width: 25%;
}
.bg-arrow-bottom {
    text-align: start;
}
.bg-arrow-bottom img{
    transform: translateY(-181px);
}
.main1 {
    background-image: url('../src/images/bg-concept.png');
    background-repeat: no-repeat;
    background-size: 350px;
    background-position-x: right;
}
.main2 {
    background-image: url('../src/images/bg-concept.png');
    background-repeat: no-repeat;
    background-size: 700px;
    background-position-x: right;
    background-position-y: 800px;
}
.footer2 {
    background-image: url('../src/images/mail.png');
    background-repeat: no-repeat;
    background-position-x: left;
}
.paires-perso {
    position: relative;
}
.arrow-right-paires {
    position: absolute;
    right: 0;
    top: 32px;
    z-index: -1;
}
.arrow-left-paires {
    position: absolute;
    left: 0;
    bottom: -73px;
    z-index: -1;
}
.text-transform-upper  {
    text-transform: uppercase;
    
}
.arrow-desktop {
    display: block;
}
.arrow-mobile {
    display: none;
}
@media (max-width: 768px) { 
    
    .carousel-section{
        overflow: hidden;
        padding: 0;
    }
    h1 {
        font-size: 25px;
    }
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 15px;
    }
    p {
        font-size: 16px;
    }
    .p-t-0{
        padding-top: 0px !important;
    }
    .p-left-80 {
        padding-left: 0;
    }
    .title-footer p {
        white-space: normal;
        padding: 0 40px;
    }
    .title-footer h2{
        margin: 0 0 40px;
    }
    .p-40 {
        padding: 0;
    }
    .uppercase {
        display: none;
    }
    .text-transform-upper {
        margin: -59px 0 40px 0;
    }
    .header-text h1 {
        margin-top: 0;
        width: 70%;
    }
    .m-t-50 {
        margin-top: 50px;
    }
    .input-newsleter {
        margin: 30px auto 0;

    }
    .bg-arrow-bottom {
        transform: translate(-49px, 56px);
        height: auto;
        width: 50%;
    }
    .submit {
        color: #000;
        height: 53px;   
        border-radius: 7px;
        font-weight: bold;
        margin-top: 50px;
    }
    
    .bg-arrow-bottom img {
        width: 145px;
    }
    .text-width {
        margin: auto;
        width: 80%;
    }
    #submitButton {
        transform: none !important;
    }
    .btn-nav-mobile button {
        background-color: #F18F01;
        border: none;
        font-weight: bold;
        line-height: 22px;
        width: 98px;
        height: 41px;
        border-radius: 5px;
        font-weight: bold;
        line-height: 20px;
        cursor: pointer;
        font-size: 10px;
    }
    nav {
        display: none
    }
    .couronne-mobile {
        display: block;
        transform: translate3d(98px, 10px, 10px);
    }
    .couronne {
        display: none;
    }
    .nav-mobile {   
        display: flex;
        justify-content: space-between;
        height: 66px;
        align-items: center;
        padding: 12px 24px;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
        background-color: #fff;
        position: fixed;
        width: 90%;
        z-index: 100;
    }
    .concept-title {
        margin: 50px 30px;
    }
    .logo-mobile {
        width: 11%;
    }
    .bg-arrow-top {
        width: 50%;
        text-align: end;
    }
    .bg-arrow-top img {
        width: 100px;
        max-width: 100%;
    }
    .left-title, .right-title {
        display: block;
    }
    .left-title {
        transform: translate3d(10px, -13px, 10px);
    }
    .right-title {
        transform: translate3d(-10px, -9px, 10px);
    }
    .icones-title {
        display: flex;
        justify-content: center;
    }
    .card-article {
        padding-left: 0 !important;
    }
    Header {
        background-position-x: 48%;
        background-size: cover;
        height: 690px;
    }
    .h1-mobile {
        display: block;
    }
    .h1-desktop {
        display: none;
    }
    .header-text2 {
        padding: 192px 30px;
    }
    .header-text1 {
        padding: 192px 30px 100px;
    }
    .header-text p {
        width: 46%;
        font-size: 12px;
    }
    .btn-nav {
        display: block;
    }
    .btn-rose button {
        width: 216px;
        font-size: 12px;
        height: 38px;
    }
    .scroll {
        display: block;
        margin: auto;
    }
    .scroll-mobile-2 {
        margin-top: 44px;
    }
    .header2 {
        height: 765px;
    }
    Header {
        padding-top: 0;
    }
    .gallery-sneakers img {
        width: 100%;
    }
    .gallery-sneakers {
        margin: 50px 0;
    }
    
    .scroll-mobile p {
        font-weight: bold;
    }
    .concept, .valeurs, .paires{
        padding: 40px;
        /* background-color: #F6F6F6; */
    }
    .artist-custom {
        padding: 0;
    }
    .concept {
        text-align: center;
    }
    .d-flex-concept {
        display: block;
    }
    .left_concept {
        width: 100%;
    }
    .right_concept {
        width: 100%;
        margin-left: 0;
    }
    .concept-stat {
        margin-top: 80px;
    }
    .concept_stat {
        margin: 50px 0 0;
        display: block;
    }
    .bgArtist {
        width: 300px;
        bottom: 8%;
    }
    .size-80, .size-80 span  {
        font-size: 40px !important;
    }
    .left_stat p, .right_stat p {
        margin-bottom: 20px;
    }
    .text-center {
        text-align: center;
    }
    .p-40 {
        padding: 40px;
    }
    .valeurs {
        background-color: #f1f1f1;
        color: #000;
        border-radius: 20px;
        padding: 40px;
        text-align: center;
        background-size: 100px;
        background-position-y: 150px;
    }
    .valeurs h2 {
        margin: 20px 0;
    }
    .cards-valeurs {
        display: block;
    }
    .card-item {
        width: 100%;
        margin-bottom: 50px;
    }
    .card-item h3 {
        margin: 20px 0;
    }
    .products {
        padding: 40px;
    }
    /* Carousel mobile*/
    .carousel-container {
        display: flex;
        transition: transform 0.5s ease-in-out;
        width: 100%;
      }
    
      .card-container {
        flex: 1;
        min-width: 70%;
        margin: 0 15px;
        transition: transform 0.5s ease-in-out;
      }
    
      .carousel .card {
        min-width: 100%;
        margin: 5px;
        padding: 10px;
        height: 200px;
      }
    
      .active {
        transform: scale(1.2);
        z-index: 2;
      }
      .carousel-section {
        display: block;
      }

      .blog {
        padding: 40px;
      }
      .visit_blog {
        display: block;
      }
      .d-flex-blog {
        display: block;
      }
      .left_blog {
        width: 100%;
        }
        .img-article {
            height: 300px;
            margin: 60px 0 40px;
        }
        .right_blog {
            margin-left: 0;
            width: 100%;
        }
        .right_blog .img-card {
            height: auto;
            width: 100%;
        }
        .img-card img {
            width: 90%;
            max-width: 100%;
            height: 90%;
        }
        .tag-blog {
            margin-bottom: 13px !important;
        }
        .left_blog .lien-article {
            margin-bottom: 60px;
        }
        .article-text h3 {
            margin-bottom: 10px; 
            width: 100%;
        }
        footer {
            padding-top: 110px;
        }
        .footer2 {
            background-position-y: -23px;
            background-size: 100px;
        }
        .input-newsleter input {
            width: 100%;
            padding-left: 0;
        }
        .input-newsleter button {
            transform: translate3d(120px, -65px, 10px);
        }
        .input-newsleter {
            width: 90%;
        }
        .input-newsleter textarea {
            padding-left: 0;
        }
        .footer-items {
            padding: 0 40px;
        }
        .copyright {
            display: none;
        }
        .copyright-mobile {
           display: block;
        }
        .footer-items {
            display: flex;
            flex-wrap: wrap;
        }
        .logo-footer {
            width: 30%;
        }
        .logo-footer img {
            width: 100%;
        }
        .logo-footer svg {
            width: 100px;
        }
        .rs-img {
            width: 50%;
            display: flex;
            justify-content: end;
        }
        .rs-img img {
            width: 20px;
        }
        .copyright-mobile {
            margin: auto;
        }
        .copyright-mobile p {
            font-size: 8px;
            font-weight: bold;
            margin: 30px 0;
        }
        .artist-1, .artist-2, .artist-4, .artist-5, .artist-7, .artist-9, .artist-10, .artist-13 {
            display: none;
        }
        .artist-6 {
            left: 95%;
        }
        .artist-3 {
            left: 25%;
        }
        .artist-14 {
            left: 37%;
            top: 40%;
        }
        .artist-8 {
            left: -6%;
            top: 27%;
        }
        .artist-12 {
            left: 72%;
            top: 65%;
        }
        .artist-11 {
            left: 35%;
            top: 70%;
        }
        .arrow-left-paires img, .arrow-right-paires img {
            width: 66px;
        }
        .arrow-left-paires, .arrow-right-paires {
            z-index: 0;
        }
        .arrow-left-paires {
            bottom: 26px;
        }
        .arrow-desktop {
            display: none;
        }
        .arrow-mobile {
            display: block;
        }
        .lien-blog {
            justify-content: end;
        }
        .paires, .products, .titles-blog {
            text-align: center;
        }
        .main2 {
            background-position-y: 700px;
            background-size: 200px;
        }

    }
